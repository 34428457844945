export enum AppointmentPathEnum {
  APPOINTMENTS = 'appointments',
  APPOINTMENT = 'appointment',
  MY_APPOINTMENTS = 'my-appointments',
  ALL_DOCTOR_SPOTS = 'get-doctor-spots',
  GET_DOCTOR_AVAILABILITY = 'get-doctor-availability',
  DOCTOR_PROGRAM = 'get-doctor-programs',
  CHANGE_STATUS = 'change-status',
  UPDATE_APPOINTMENT = 'update-appointment',
  CANCEL_APPOINTMENT = 'cancel-appointment',
  RESCHEDULE = 'reschedule',
  ALL_APPOINTMENTS = 'all',
  ALL_DOCTOR_APPOINTMENTS = 'all-doctor-appointments',
  COUNT_BY_DOCTOR = 'count-by-doctor',
  COUNT_ALL = 'count-all',
  CREATE = 'create',
  CREATE_BY_DOCTOR = 'create-by-doctor',
  EXPORT = 'export',
}
