
export enum SpecializationEnum {
  NONE = 'NONE',
  CARDIOLOGY = 'CARDIOLOGY',
  DERMATOLOGY = 'DERMATOLOGY',
  ENDOCRINOLOGY = 'ENDOCRINOLOGY',
  GASTROENTEROLOGY = 'GASTROENTEROLOGY',
  GYNECOLOGY = 'GYNECOLOGY',
  HEMATOLOGY = 'HEMATOLOGY',
  NEPHROLOGY = 'NEPHROLOGY',
  NEUROLOGY = 'NEUROLOGY',
  ONCOLOGY = 'ONCOLOGY',
  OPHTHALMOLOGY = 'OPHTHALMOLOGY',
  ORTHOPEDICS = 'ORTHOPEDICS',
  OTOLARYNGOLOGY = 'OTOLARYNGOLOGY',
  PEDIATRICS = 'PEDIATRICS',
  PSYCHIATRY = 'PSYCHIATRY',
  PULMONOLOGY = 'PULMONOLOGY',
  RHEUMATOLOGY = 'RHEUMATOLOGY',
  UROLOGY = 'UROLOGY'
}
